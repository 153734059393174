import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueApexCharts from "vue3-apexcharts";
import Vue3EasyDataTable from 'vue3-easy-data-table'
import VueAwesomePaginate from "vue-awesome-paginate";
import { createStore } from 'vuex'
import OpenLayersMap from "vue3-openlayers";
// import VueMatomo from 'vue-matomo'
import { createHead } from '@vueuse/head'
import { FileManagerPlugin } from "@syncfusion/ej2-vue-filemanager";
import JsonExcel from "vue-json-excel3";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./assets/style/main.scss"
import 'vue3-easy-data-table/dist/style.css'
import "vue-awesome-paginate/dist/style.css";
import "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "vue3-openlayers/styles.css"
import "qalendar/dist/style.css";

const head = createHead()

const store = createStore({
    state:{
      apiUrl:process.env.VUE_APP_ROOT_API,
      baseUrl:window.location.origin,
      spldApi: process.env.VUE_APP_SPLD_API,
      isAccebility:{
        modeSuara:false,
        modeMalam:false
      }
    },
    mutations: {
      modeSuara(state, option){
        state.isAccebility.modeSuara = option
      },
      modeMalam(state, option){
        state.isAccebility.modeMalam = option
      }
    }
})



createApp(App)
.use(router)
.use(VueApexCharts)
.use(VueAwesomePaginate)
.use(store)
.use(OpenLayersMap)
// .use(VueMatomo, {
//   // host: process.env.VUE_APP_MATOMO_URL, 
//   // siteId: process.env.VUE_APP_MATOMO_SITE_ID
//   host: 'https://wsa.jakarta.go.id', 
//   siteId: 19,
//   router: router,
//   enableLinkTracking: true,
//   requireConsent: false,
//   trackInitialView: true,
//   trackerFileName: "matomo",
//   debug: true
// })
.use(head)
.use(FileManagerPlugin)
.component('EasyDataTable', Vue3EasyDataTable)
.component("downloadExcel", JsonExcel)
.mount('#app')

// window._paq.push(['trackPageView'])