<template>
  <div v-bind:style="homeAccebility(isAccebility)">
    <section>
      <Navbars/>
    </section>
    <section class="contents">
      <router-view/>
    </section>
    <section>
      <Footers/>
    </section>
    <!-- <AccebilityWidget/>
    <FeedbackWidget/> -->
  </div>
</template>

<script>
import Navbars from '../components/Navbars.vue'
import Footers from '../components/Footers.vue'
// import AccebilityWidget from '@/components/AccebilityWidget.vue'
// import FeedbackWidget from '@/components/FeedbackWidget.vue'
export default {
  name: 'MainHome',
  components: {
    Navbars,
    Footers,
    // AccebilityWidget,
    // FeedbackWidget
  },
  data(){
    return{
      isAccebility:this.$store.state.isAccebility,
    }
  },
  mounted(){
  },
  methods:{
    homeAccebility(isAccebility){
      if(isAccebility.modeMalam){
        return{
          backgroundColor: 'black'
        }
      } else {
        return{
          backgroundColor: 'white'
        }
      }
    },
  }
}
</script>
  