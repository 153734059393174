/* eslint-disable */

import { createRouter, createWebHistory } from 'vue-router'

//define a routes
const routes = [
    {
        path: '/',
        redirect: "/home"
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../src/pages/Home/IndexHome.vue'),
        meta:{title:'Home',page:"index",pageTitle:'Satudata Jakarta - Home'}
    },
    {
        path: '/search',
        name: 'search',
        component: () => import('../src/pages/Home/ResultSearch.vue'),
        meta:{title:'Pencarian',page:"index",pageTitle:'Satudata Jakarta - Pencarian'}
    },
    {
        path: '/kalender',
        name: 'kalender',
        component: () => import('../src/pages/Home/Kalender.vue'),
        meta:{title:'Kalender',page:"index",pageTitle:'Satudata Jakarta - Kalender'}
    },
    // {
    //     path: '/search',
    //     name: 'search',
    //     component: () => import('../src/pages/Home/ResultSearchRevamp.vue'),
    //     meta:{title:'Pencarian',page:"index"}
    // },
    {
        path: '/organisasi',
        name: 'organisasi',
        component: () => import('../src/pages/Opendata/OrganisasiOpendata.vue'),
        meta:{title:'Organisasi',page:"open-data",pageTitle:'Satudata Jakarta - Organisasi'}
    },
    {
        path: '/topik',
        name: 'topik',
        component: () => import('../src/pages/Opendata/TopikOpendata.vue'),
        meta:{title:'Topik',page:"open-data",pageTitle:'Satudata Jakarta - Topik'}
    },
    {
        path: '/open-data',
        name: 'index-open-data',
        component: () => import('../src/pages/Opendata/IndexOpendata.vue'),
        meta:{title:'Home',page:"open-data",pageTitle:'Satudata Jakarta - Open Data'}
    },
    {
        path: '/open-data/detail',
        name: 'detail-open-data',
        component: () => import('../src/pages/Opendata/DetailOpendata.vue'),
        meta:{title:'Detail',page:"open-data",pageTitle:'Satudata Jakarta - Detail Opendata'}
    },
    {
        path: '/open-data/detail/:page_url',
        name: 'detail-open-data-page-url',
        component: () => import('../src/pages/Opendata/DetailOpendata.vue'),
        meta:{title:'Detail',page:"open-data",pageTitle:'Satudata Jakarta - Detail Opendata'}
    },
    {
        path: '/statistik-sektoral',
        name: 'index-statistik-sektoral',
        component: () => import('../src/pages/Statistiksektoral/IndexStatistiksektoral.vue'),
        meta:{title:'Home',page:"statistik-sektoral",pageTitle:'Satudata Jakarta - Statistik Sektoral'}
    },
    {
        path: '/statistik-sektoral/detail',
        name: 'detail-statistik-sektoral',
        component: () => import('../src/pages/Statistiksektoral/DetailStatistiksektoral.vue'),
        meta:{title:'Detail',page:"statistik-sektoral",pageTitle:'Satudata Jakarta - Detail Statistik Sektoral'}
    },
    {
        path: '/statistik-sektoral/detail/:page_url',
        name: 'detail-statistik-sektoral-page-url',
        component: () => import('../src/pages/Statistiksektoral/DetailStatistiksektoral.vue'),
        meta:{title:'Detail',page:"statistik-sektoral", pageTitle:'Satudata Jakarta - Detail Statistik Sektoral'}
    },
    {
        path: '/jaksurvei',
        name: 'index-jaksurvei',
        component: () => import('../src/pages/Jaksurvei/IndexJaksurvei.vue'),
        meta:{title:'Home',page:"jaksurvei",pageTitle:'Satudata Jakarta - Jaksurvei'}
    },
    {
        path: '/spld',
        name: 'index-spld',
        component: () => import('../src/pages/Spld/IndexSpld.vue'),
        meta:{title:'Home',page:"spld",pageTitle:'Satudata Jakarta - Spld'}
    },
    {
        path: '/dashboard',
        name: 'index-dashboard-tantangan',
        component: () => import('../src/pages/Home/DashboardTantanganjakarta.vue'),
        meta:{title:'Dashboard',page:"dashboard",pageTitle:'Satudata Jakarta - Dashboard'}
    },
    {
        path: '/dashboard-insight',
        name: 'index-dashboard-insight',
        component: () => import('../src/pages/Home/DashboardInsight.vue'),
        meta:{title:'Dashboard Insight',page:"dashboard-insight",pageTitle:'Satudata Jakarta - Insight'}
    },
    {
        path: '/jakarta-satu',
        name: 'index-jakarta-satu',
        component: () => import('../src/pages/Jakartasatu/IndexJakartasatu.vue'),
        meta:{title:'Home',page:"jakarta-satu",pageTitle:'Satudata Jakarta - Jakarta Satu'}
    },
    {
        path: '/jakarta-satu/detail',
        name: 'detail-jakarta-satu',
        component: () => import('../src/pages/Jakartasatu/DetailJakartasatu.vue'),
        meta:{title:'Detail',page:"jakarta-satu",pageTitle:'Satudata Jakarta - Detail Jakarta Satu'}
    },
    {
        path: '/open-data/filemanager',
        name: 'filemanager-opendata',
        component: () => import('../src/pages/Opendata/FilemanagerOpendata.vue'),
        meta:{title:'Filemanager',page:"filemanager-opendata",pageTitle:'Satudata Jakarta - Filemanager Jakarta Satu'}
    }

]

//create router
const router = createRouter({
    history: createWebHistory(),
    routes
})

// router.beforeEach((to) => {
//     const titleFromParams = to.params?.pageTitle
  
//     if (titleFromParams) {
//       document.title = `${titleFromParams} - ${document.title}`
//     } else {
//       document.title = to.meta?.pageTitle ?? 'Default Title'
//     }
// })

export default router