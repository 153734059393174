<template>
  <div :class="classContainer">
    <nav v-bind:class="navbarsClass">
      <div class="container">
        <a class="navbar-brand" href="/home"><img v-bind:src="imageLogo" :style="imagesBrand"></a>
        <!-- <div class="input-group search flex-nowrap" v-if="showSearch">
          <input type="text" class="form-control" v-model="qSearch" placeholder="Cari apa disini ...">
          <span class="input-group-text" id="addon-wrapping" @click="search()">
            <img v-bind:src="require('@/assets/icon/icon-search.png')">
          </span>
        </div> -->
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <img v-bind:src="imageMenuicon" class="navbar-toggler-icon">
        </button>
        <div class="navbar-collapse offcanvas offcanvas-end" tabindex="-1" id="navbarSupportedContent" :style="'background-color:'+bgNavbar">
          <button type="button" class="btn-close mt-4" data-bs-dismiss="offcanvas" v-if="!showMegamenu"></button>
          <img v-bind:src="imageLogoDark" class="d-block d-sm-none img img-fluid px-5 py-2" :style="'width:80%;'">
          <ul class="navbar-nav ms-auto mb-2 mt-2 mb-lg-0" v-if="this.currentRouteName == 'spld'">
            <li class="nav-item" v-for="itemsMenu in itemMenuspld" :key="itemsMenu">
              <a class="nav-link" :style="styleHover(itemsMenu.page)" :href="itemsMenu.page" aria-current="page" @mouseover="mouseover(itemsMenu.mouseover)">{{ itemsMenu.title }}</a>
            </li>
            <li class="nav-item" hidden>
              <!-- <button class="button-lang">
                <span class="icon-togle" v-if="this.$route.query.lang == 'en'" @click="togleLang('id')"><img src="../assets/icon/flag-id.png" class="icon-flag"> Id</span>
                <span class="icon-togle" v-else @click="togleLang('en')"><img src="../assets/icon/flag-en.png" class="icon-flag"> En</span>
              </button> -->
            </li>
          </ul>
          <ul class="navbar-nav  mt-2 mb-2 mb-lg-0" v-else>
            <li class="nav-item" v-for="itemsMenu in itemMenu" :key="itemsMenu">
              <a :target="itemsMenu.target" class="nav-link" :style="styleHover(itemsMenu.page)" :href="itemsMenu.page" aria-current="page" @mouseover="mouseover(itemsMenu.mouseover)">{{ itemsMenu.title }}</a>
            </li>
            <li class="nav-item" hidden>
              <!-- <button class="button-lang">
                <span class="icon-togle" v-if="this.$route.query.lang == 'en'" @click="togleLang('id')"><img src="../assets/icon/flag-id.png" class="icon-flag"> Id</span>
                <span class="icon-togle" v-else @click="togleLang('en')"><img src="../assets/icon/flag-en.png" class="icon-flag"> En</span>
              </button> -->
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="megamenu" v-if="isActive && showMegamenu" @mouseleave="isActive = false">
      <div class="container">
        <div class="row">
          <div class="col-sm-4">
            <img class="megamenu-logo" v-bind:src="require('@/assets/navbar/'+imagesMegamenu)">
          </div>
          <div class="col-sm-2" v-for="menus,index in dataMegamenu" :key="index">
            <h5 class="megamenu-title">{{ dashtoSpace(menus.nama) }}</h5>
            <ul class="megamenu-list" v-for="listMenus,indexList in menus.list_menu" :key="indexList">
              <li class="megamenu-items" @click="toPage(listMenus)">{{ dashtoSpace(listMenus) }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import axios from 'axios'
export default {
  name: 'NavbarComponents',
  components: {

  },
  data(){
    return{
      baseUrls:'../../',
      navbarsClass: 'navbar navbar-expand-lg navbar-light bg-light',
      imageLogo:require('@/assets/logo/logo-light.png'),
      imageLogoDark:require('@/assets/logo/logo-dark.png'),
      imageMenuicon:require('@/assets/icon/menu-burger.png'),
      // bgNavbar:'black',
      classContainer:'navbars',
      isActive:false,
      itemMenu:[
        {
          page:'/home',
          mouseover:'home',
          title:'Home',
          target:''
        },
        {
          page:'/open-data',
          mouseover:'open-data',
          title:'Open Data',
          target:''
        },
        {
          page:'/statistik-sektoral',
          mouseover:'statistik-sektoral',
          title:'Statistik Sektoral',
          target:''
        },
        {
          page:'https://spld.jakarta.go.id/',
          mouseover:'spld',
          title:'SPLD',
          target:'_balnk'
        },
        {
          page:'/jakarta-satu',
          mouseover:'jakarta-satu',
          title:'Jakarta Satu',
          target:''
        }
      ],
      itemMenuspld:[
        {
          page:'/spld',
          mouseover:'katalog-data',
          title:'Katalog Data'
        },
        {
          page:'/spld',
          mouseover:'layanan-data',
          title:'Layanan Data'
        },
        {
          page:'/spld',
          mouseover:'layanan-utama',
          title:'Layanan Utama'
        },
        {
          page:'/spld',
          mouseover:'pengajuan',
          title:'Pengajuan'
        },
        {
          page:'/spld',
          mouseover:'login',
          title:'Login'
        }
      ],
      dataMegamenu:[],
      imagesMegamenu:"images1.png",
      windowWidth: window.innerWidth,
      showMegamenu: true,
      paramsData:[],
      showSearch:false,
      scrollpx: 0,
      imagesBrand:'',
      qSearch: '',
      isAccebility:this.$store.state.isAccebility,
      cekModemalam:''
    }
  },
  mounted(){

      this.paramsData = {
      "q":this.$route.query.q,
      "halaman":this.$route.query.halaman,
      "kategori":this.$route.query.kategori,
      "topik":this.$route.query.topik,
      "organisasi":this.$route.query.organisasi,
      "status":this.$route.query.status,
      "sort":this.$route.query.sort,
      "page_no":this.$route.query.page_no,
      "tags":this.$route.query.tags
    }

    if(this.$route.meta.type == 'sub-index'){
      this.baseUrls = '../../../'
    } else {
      this.baseUrls = '../'
    }

    if(this.$route.meta.page == 'spld'){
      this.imageLogo = require('@/assets/logo/logo-jaya-raya.png')
    }
  
    axios.get(`${this.baseUrls}layout.json`).then(res=>{
      this.dataMegamenu = res.data[0].sub_menu
    })

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    if(this.windowWidth <= '768'){
      this.showMegamenu = false
    } else {
      this.showMegamenu = true
    }
    
    if(this.$store.state.isAccebility.modeMalam){
      this.cekModemalam = 'mode malam'
    } else {
      this.cekModemalam = 'mode siang'
    }

    if(window.location.pathname == '/' || window.location.pathname == '/home' || window.location.pathname == '/open-data' || window.location.pathname == '/jakarta-satu'){
      this.navbarsClass = 'navbar navbar-expand-lg navbar-light bg-light'
      this.imageLogo = require('@/assets/logo/logo-light.png')
    } else {
      this.navbarsClass = 'navbar navbar-expand-lg navbar-light bg-dark'
      this.imageLogo = require('@/assets/logo/logo-dark.png')
    }
    

  },
  beforeUnmount () { 
    window.removeEventListener("resize", this.eventChngescreen);
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener("resize", this.eventChngescreen);
  },
  computed: {
      currentRouteName() {
          return this.$route.meta.page;
      }
  },
  methods:{
    // navbarsClass(isAccebility){
    //   if(this.currentRouteName == 'spld'){
    //     this.classContainer = 'navbars-spld'
    //     if(isAccebility.modeMalam){
    //       this.imageLogo = require('@/assets/logo/logo-jaya-raya.png')
    //       this.bgNavbar = 'black'
    //       return 'navbar navbar-expand-lg navbar-dark bg-dark'
    //     } else {
    //       this.imageLogo = require('@/assets/logo/logo-jaya-raya.png')
    //       this.bgNavbar = 'white'
    //       return 'navbar navbar-expand-lg navbar-light bg-light'
    //     }
    //   } else {
    //     this.classContainer = 'navbars'
    //     if(isAccebility.modeMalam){
    //       this.imageLogo = require('@/assets/logo/logo-light.png')
    //       this.imageMenuicon = require('@/assets/icon/menu-burger-light.png')
    //       this.bgNavbar = 'black'
    //       return 'navbar navbar-expand-lg navbar-dark bg-dark'
    //     } else {
    //       this.imageLogo = require('@/assets/logo/logo-light.png')
    //       this.imageMenuicon = require('@/assets/icon/menu-burger.png')
    //       this.bgNavbar = 'white'
    //       return 'navbar navbar-expand-lg navbar-light bg-light'
    //     }
    //   }
    // },
    handleScroll() {
      this.scrollpx = window.scrollY;
      if(this.scrollpx>0){
        this.navbarsClass =  'navbar navbar-expand-lg navbar-light bg-dark'
        this.imageLogo = require('@/assets/logo/logo-dark.png')
      } else {
        if(window.location.pathname == '/' || window.location.pathname == '/home' || window.location.pathname == '/open-data' || window.location.pathname == '/jakarta-satu'){
          this.navbarsClass = 'navbar navbar-expand-lg navbar-light bg-light'
          this.imageLogo = require('@/assets/logo/logo-light.png')
        } else {
          this.navbarsClass = 'navbar navbar-expand-lg navbar-light bg-dark'
          this.imageLogo = require('@/assets/logo/logo-dark.png')
        }
      }

      // if(this.scrollpx>=210){
      //   this.showSearch = true
      //   if(this.windowWidth <= '768'){
      //     this.imagesBrand = {
      //       width:'80px',
      //       height:'32px'
      //     }
      //   } 
      // } else {
      //   this.showSearch = false
      //   this.imagesBrand = {
      //       width:'130px',
      //       height:'55px'
      //     }
      // }
    },
    styleHover(menu){
      if(window.location.pathname == menu){
        return {
          fontFamily:'poppins-medium',
          textDecoration: 'underline',
          textUnderlineOffset: '8px',
          textDecorationColor: '#052158',
          textDecorationThickness: '3px'
        }
      }
    },
    togleLang(lang){
      window.location.href = window.location.pathname+"?lang="+lang
    },
    dashtoSpace(menu){
      return menu.replace('-',' ')
    },
    mouseover(menu){
      this.accebility(menu)
      if(menu == 'home'){
        // this.isActive = true
        axios.get(`${this.baseUrls}layout.json`).then(res=>{
          this.imagesMegamenu = res.data[0].images
          this.dataMegamenu = res.data[0].sub_menu
        })
      } else if(menu == 'open-data'){
        // this.isActive = true
        axios.get(`${this.baseUrls}layout.json`).then(res=>{
          this.imagesMegamenu = res.data[1].images
          this.dataMegamenu = res.data[1].sub_menu
        })
      } else if(menu == 'statistik-sektoral'){
        // this.isActive = true
        axios.get(`${this.baseUrls}layout.json`).then(res=>{
          this.imagesMegamenu = res.data[2].images
          this.dataMegamenu = res.data[2].sub_menu
        })
      } else if(menu == 'spld'){
        // this.isActive = true
        axios.get(`${this.baseUrls}layout.json`).then(res=>{
          this.imagesMegamenu = res.data[3].images
          this.dataMegamenu = res.data[3].sub_menu
        })
      } else if(menu == 'jakarta-satu'){
        // this.isActive = true
        axios.get(`${this.baseUrls}layout.json`).then(res=>{
          this.imagesMegamenu = res.data[4].images
          this.dataMegamenu = res.data[4].sub_menu
        })
      }
    },
    toPage(menu){
     window.location.href = "/search?q=&halaman=all&kategori=" + menu + "&topik=all&organisasi=all&status=all&sort=desc&page_no=1&show_filter=true&lang=id"
    },
    eventChngescreen(e) {
      this.windowWidth = e.currentTarget.screen.availWidth
      if(this.windowWidth <= '768'){
        this.showMegamenu = false
      } else {
        this.showMegamenu = true
      }
    },
    search() {
      window.location.href = "search?q=" + this.qSearch + "&halaman=all&kategori=all&topik=all&organisasi=all&status=all&sort=desc&page_no=1&show_filter=true&lang=id"
    },
    accebility(voice){
      if(this.isAccebility.modeSuara){
        let msg = new SpeechSynthesisUtterance();
        let voices = window.speechSynthesis.getVoices()
        msg.voice = voices[160];
        msg.text = voice;
        msg.volume = 10;
        msg.rate = 1;
        msg.pitch = 1;
        window.speechSynthesis.cancel();
        window.speechSynthesis.speak(msg);
      }
    }
  }
}
</script>
    