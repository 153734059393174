<template>
  <MainHome/>
</template>

<script>
import MainHome from './layouts/MainHome.vue'
export default {
  name: 'App',
  components: {
    MainHome
  }
}
</script>

<style>
@import "../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-layouts/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-vue-filemanager/styles/bootstrap5.css";
</style>