<template>
  <div class="footers" v-bind:style="footerAccebility(isAccebility)">
    <div class="container">
      <div class="row">
        <div v-bind:class="columnStyle.columnAddress+ ' desc'">
          <p>Kontak Kami :</p>
          <p>Bidang Data dan Statistik <br> Jl. Medan Merdeka Selatan <br> Jakarta Pusat</p>
          <p>Help Desk : 0813 4000 3614</p>
          <p>Email : satudata@jakarta.go.id</p>
        </div>
        <div v-bind:class="columnStyle.columnLogo+ ' logo'">
          <a href="https://jakarta.go.id/" target="_blank"><img v-bind:src="require('@/assets/logo/logo-jakgo.png')"></a>
          <a href="https://ppid.jakarta.go.id/" target="_blank"><img v-bind:src="require('@/assets/logo/logo-ppid.png')"></a>
          <a href="https://www.beritajakarta.id/" target="_blank"><img v-bind:src="require('@/assets/logo/logo-bj.png')"></a>
          <a href="https://smartcity.jakarta.go.id/id/" target="_blank"><img v-bind:src="require('@/assets/logo/logo-jsc.png')"></a>
          <a href="https://jalahoaks.jakarta.go.id/" target="_blank"><img v-bind:src="require('@/assets/logo/logo-jh.png')"></a>
          <a href="https://data.go.id/home" target="_blank"><img v-bind:src="require('@/assets/logo/logo-sdi.png')"></a>
        </div>
        <div v-bind:class="columnStyle.columnLogin+ ' button-masuk '">
          <button class="btn btn-secondary" @click="buttonMasuk()">Masuk</button>
          <p class="viewer"><img alt="logo-trafic-satudata-jakarta" src="../assets/icon/trafic.png">Kunjungan: {{ dailyView }} <br> <img src="../assets/icon/insight.png" alt="icon-insight-satudata-jakarta">Total Kunjungan: {{ totalView }}</p>
        </div>
        <div class="col-12 copy">
          <p>Copyright &copy; {{ getYears }} Dinas Komunikasi, Informatika dan Statistik Provinsi DKI Jakarta. All Right Reserved</p>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import axios from 'axios'
export default {
  name: 'FooterComponents',
  components: {
        
  },
  data(){
    return{
      baseApi:this.$store.state.apiUrl,
      isAccebility:this.$store.state.isAccebility,
      dailyView:0,
      totalView:0,
      getYears:0,
      columnStyle: [],
      windowWidth: window.innerWidth,
    }
  },
  mounted(){
        
    var paramsDataharian = {
      type:'Insights.getInsightsOverview',
      period:'day',
      date:'today' 
    }
    axios.post(`${this.baseApi}/api/v2/satudata/matomo-analytics`, paramsDataharian).then(res=>{
      let dataRes = res.data['URL Halaman']
      let countVisitharian = 0
      if (dataRes !== undefined){
        for(var i=0; i<dataRes.length; i++){
          if(dataRes[i].label != 'backend'){
            countVisitharian += dataRes[i].nb_visits
          }
        }
      }
      
      this.dailyView = countVisitharian
    })

    let dMatomo = new Date()
    let monthMatomo = dMatomo.getMonth()+1
    let currentDatematomo = dMatomo.getFullYear()+'-'+monthMatomo+'-'+dMatomo.getDay()

    var paramsDatasemua = {
      type:'Insights.getInsightsOverview',
      period:'range',
      date:'2023-08-15,'+currentDatematomo 
    }
    axios.post(`${this.baseApi}/api/v2/satudata/matomo-analytics`, paramsDatasemua).then(res=>{
      let dataRes = res.data['URL Halaman']
      let countVisisemua = 0
      if (dataRes !== undefined){
        for(var i=0; i<dataRes.length; i++){
          if(dataRes[i].label != 'backend'){
            countVisisemua += dataRes[i].nb_visits
          }
        }
      }
      this.totalView = countVisisemua
    })

    const date = new Date();
    this.getYears = date.getFullYear()

    if (this.windowWidth <= "430") {
      this.columnStyle = {
        columnAddress: "col-6",
        columnLogo: "col-6",
        columnLogin: "col-6",
      };
    } else {
        this.columnStyle = {
        columnAddress: "col-3",
        columnLogo: "col-7",
        columnLogin: "col-2",
      }; 
    }

  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods:{
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    buttonMasuk(){
      window.open(this.baseApi+'/login', '_blank')
    },
    footerAccebility(isAccebility){
      if(isAccebility.modeMalam){
        return{
          backgroundColor: 'black'
        }
      } else {
        return{
          // backgroundColor: '#052158'
        }
      }
    },
  }
}
</script>
    